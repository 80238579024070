/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Grid,
  RACTextbox,
  RACDatePicker,
  RACButton,
  Box,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { headerStyles } from '../../../headerStyles';
import { fieldDeviceStyles } from '../../../fieldDeviceSty';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SearchImageDefault } from '../../../assets/images/search-btn.svg';
import moment from 'moment';
import { GetCustomerInfoOptions } from '../../../api/user';
import { CustomDropdown } from '../../GlobalSearch/CustomDropdown';
export interface dropdown {
  label: string;
  value: any;
}

export const GlobalSearch = (props: any) => {
  const classes = headerStyles();
  const classes1 = fieldDeviceStyles();
  const history = useHistory();
  const searchCriteriaObject = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    ssn1: '',
    ssn2: '',
    ssn3: '',
    phoneNumber: '',
    agreementNumber: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    state: '',
  };
  const [searchCriteria, setSearchCriteria] = useState(searchCriteriaObject);
  const [disableSearchBtn, setDisableSearchBtn] = useState(true);
  const [datesetting, setDatesetting] = useState('');
  const [minDate, setMinDate] = useState('');
  const DISPLAYSEARCHBAR = 'DISPLAYSEARCHBAR';
  const [states, setStates] = useState<dropdown[]>([
    { label: 'Select', value: '0' },
  ]);
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('props.disPlaySearchBar in gbl', props.disPlaySearchBar);
  }, [props.disPlaySearchBar]);

  useEffect(() => {
    fetchDropDowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDropDowns = async () => {
    try {
      setDropDownLoading(true);
      const dropDownResponse = await GetCustomerInfoOptions('0');
      const statesDropDown = dropDownResponse?.stateList?.getStateResponse;
      console.log('statesDropDown', statesDropDown);
      if (statesDropDown?.length > 0) {
        const formattedStates: dropdown[] =
          getFormatedStatesDropDown(statesDropDown);
        setStates(formattedStates);
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      setDropDownLoading(false);
    }
  };

  const getFormatedStatesDropDown = (statesDropDown: any, location?: any) => {
    const states = statesDropDown.map((data: any) => ({
      label: data.abbreviation,
      value: location ? data.stateProvinceId : data.abbreviation,
    }));

    const sortedStates = states.sort((a: any, b: any) =>
      a.label.localeCompare(b.label)
    );
    return [{ label: 'Select', value: '0' }, ...sortedStates];
  };

  useEffect(() => {
    if (
      searchCriteria.firstName.length + searchCriteria.lastName.length >= 3 ||
      searchCriteria.dateOfBirth ||
      searchCriteria.phoneNumber.length == 14 ||
      searchCriteria.agreementNumber.trim().length >= 4 ||
      searchCriteria.addressLine1.trim().length >= 5 ||
      searchCriteria.addressLine2.trim().length >= 5 ||
      searchCriteria.city.trim().length >= 3 ||
      (searchCriteria.state.length > 1 && searchCriteria.state !== 'Select') ||
      searchCriteria.zip.length > 1 ||
      searchCriteria.ssn1.length +
        searchCriteria.ssn2.length +
        searchCriteria.ssn3.length ==
        9
    ) {
      setDisableSearchBtn(false);
    } else {
      setDisableSearchBtn(true);
    }
  }, [searchCriteria]);

  useEffect(() => {
    setDatesetting(moment().subtract(18, 'years').format('YYYY-MM-DD'));
    setMinDate(moment().subtract(110, 'years').format('YYYY-MM-DD'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHandler = (e: any, src: any) => {
    const updateSearchCriteria = (name: string, value: any) => {
      setSearchCriteria((prev) => ({ ...prev, [name]: value }));
    };

    const handleSSNFocus = (
      currentElId: string,
      nextElId: string,
      length: number
    ) => {
      const currentEl: any = document.getElementById(currentElId);
      const nextEl: any = document.getElementById(nextElId);
      if (
        currentEl.value.replace(/[^0-9\b]/gi, '').length === length &&
        currentEl === document.activeElement
      ) {
        nextEl.focus();
      }
    };

    switch (src) {
      case 'NAME':
        updateSearchCriteria(
          e.target.name,
          e.target.value.replace(/[^A-Z-',.; a-z]/gi, '')
        );
        break;
      case 'DOB':
        updateSearchCriteria('dateOfBirth', e);
        break;
      case 'PHONE':
      case 'agreementNumber':
      case 'addressLine1':
      case 'addressLine2':
      case 'city':
        updateSearchCriteria(e.target.name, e.target.value);
        break;
      case 'state':
        updateSearchCriteria(src, e);
        break;
      case 'SSN1':
        updateSearchCriteria(
          e.target.name,
          e.target.value.replace(/[^0-9\b]/gi, '')
        );
        handleSSNFocus('a11y_ssn1', 'a11y_ssn2', 3);
        break;
      case 'SSN2':
        updateSearchCriteria(
          e.target.name,
          e.target.value.replace(/[^0-9\b]/gi, '')
        );
        handleSSNFocus('a11y_ssn2', 'a11y_ssn3', 2);
        handleSSNFocus('a11y_ssn2', 'a11y_ssn1', 0);
        break;
      case 'SSN3':
        updateSearchCriteria(
          e.target.name,
          e.target.value.replace(/[^0-9\b]/gi, '')
        );
        handleSSNFocus('a11y_ssn3', 'a11y_ssn2', 0);
        break;
      case 'zip':
        updateSearchCriteria(e.target.name, e.target.value);
        break;
      default:
        break;
    }
  };

  const invokeRedirection = () => {
    clickedOutside();
    history.push({
      pathname: '/customer/searchcustomer',
      state: {
        FirstName: searchCriteria.firstName,
        LastName: searchCriteria.lastName,
        DOB: searchCriteria.dateOfBirth,
        PhoneNumber: searchCriteria.phoneNumber.replace(/\D/g, ''),
        SSN1: searchCriteria.ssn1,
        SSN2: searchCriteria.ssn2,
        SSN3: searchCriteria.ssn3,
        agreementNumber: searchCriteria.agreementNumber,
        addressLine1: searchCriteria.addressLine1,
        addressLine2: searchCriteria.addressLine2,
        zip: searchCriteria.zip,
        city: searchCriteria.city,
        state: searchCriteria.state,
      },
    });
  };

  const clickedOutside = () => {
    // eslint-disable-next-line no-console
    console.log('Reached the outside click');
    setSearchCriteria(searchCriteriaObject);
    props.searchOutsideClick('');
  };

  const enterEncountered = (e: any) => {
    if (e.key === 'Enter' && !disableSearchBtn) {
      invokeRedirection();
    }
  };

  function FirstRowDependency() {
    return (
      <>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="lastName"
            value={searchCriteria.lastName}
            inputlabel="Last Name"
            maxlength={30}
            OnChange={(event) => searchHandler(event, 'NAME')}
            isCurrency={false}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="firstName"
            inputlabel="First Name"
            value={searchCriteria.firstName}
            maxlength={30}
            isCurrency={false}
            OnKeydown={(e) => enterEncountered(e)}
            OnChange={(event) => searchHandler(event, 'NAME')}
          />
        </Grid>
        <Grid item md={4} lg={2} sm={6} className={classes.datePicker}>
          <Box
            component="div"
            className={`${classes.formLabel} ${classes.datePickerAddCo}`}
          >
            Date of Birth
          </Box>
          <RACDatePicker
            id="a11y_dob"
            label=""
            value={searchCriteria.dateOfBirth}
            name={'Dob'}
            onChange={(event: any) => searchHandler(event, 'DOB')}
            inputProps={{ max: datesetting, min: minDate }}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6} className={classes.spacerPT1}>
          <label htmlFor="a11y_ssn" className={classes.formLabel}>
            Social Security# / ITIN#
          </label>
          <Grid container spacing={2} className={classes.ssnpadding}>
            <Grid item md={4} sm={4}>
              <RACTextbox
                type="password"
                name="ssn1"
                value={searchCriteria.ssn1}
                placeHolder={'xxx'}
                id="a11y_ssn1"
                className={classes.textCenterSSN}
                maxlength={3}
                OnChange={(event) => searchHandler(event, 'SSN1')}
                isCurrency={false}
              />
            </Grid>
            <Grid item md={3} sm={3}>
              <RACTextbox
                type="password"
                name="ssn2"
                value={searchCriteria.ssn2}
                placeHolder={'xx'}
                id="a11y_ssn2"
                className={classes.textCenterSSN}
                maxlength={2}
                OnChange={(event) => searchHandler(event, 'SSN2')}
                isCurrency={false}
              />
            </Grid>
            <Grid item md={5} sm={5}>
              <RACTextbox
                type="text"
                name="ssn3"
                value={searchCriteria.ssn3}
                placeHolder="xxxx"
                id="a11y_ssn3"
                className={classes.textCenterSSN}
                maxlength={4}
                OnChange={(event) => searchHandler(event, 'SSN3')}
                isCurrency={false}
                OnKeydown={(e) => enterEncountered(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="phoneno"
            maxlength={14}
            inputlabel="Phone Number"
            id="a11y_phonenumber"
            name="phoneNumber"
            value={searchCriteria.phoneNumber}
            OnChange={(event) => searchHandler(event, 'PHONE')}
            isCurrency={false}
            OnKeydown={(e) => enterEncountered(e)}
            pattern="\D*"
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            maxlength={19}
            inputlabel="Agreement #"
            id="a11y_agreementumber"
            name="agreementNumber"
            value={searchCriteria.agreementNumber}
            OnChange={(event) => searchHandler(event, 'agreementNumber')}
            isCurrency={false}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>
      </>
    );
  }

  function secondRowFirstDependency() {
    return (
      <>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="addressLine1"
            placeHolder={'Address Line 1'}
            inputlabel="Address Line 1"
            maxlength={30}
            value={searchCriteria.addressLine1}
            inputLabelClassname={classes.mb1}
            OnChange={(event) => searchHandler(event, 'addressLine1')}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6}>
          <RACTextbox
            type="text"
            name="addressLine2"
            placeHolder={'Address 2, APT #, Unit #, P.O. Box'}
            inputlabel="Address Line 2"
            value={searchCriteria.addressLine2}
            inputLabelClassname={classes.mb1}
            maxlength={30}
            OnChange={(event) => searchHandler(event, 'addressLine2')}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>
      </>
    );
  }

  function secondRowSecondDependency() {
    return (
      <Grid container spacing={2} className={`${classes.ms2}`} md={4}>
        <Grid item md={4}>
          <RACTextbox
            type="text"
            name="zip"
            inputlabel="Zip"
            placeHolder={'Zip code'}
            maxlength={10}
            minlength={5}
            value={searchCriteria.zip}
            inputLabelClassname={classes.mb1}
            OnChange={(e) => {
              let zipCode = ('' + e.target.value).replace(/\D/g, '');
              zipCode =
                zipCode.length > 5
                  ? zipCode.slice(0, 5) + '-' + zipCode.slice(5)
                  : zipCode;
              e.target.name = 'zip';
              e.target.value = zipCode;
              searchHandler(e, 'zip');
            }}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>

        <Grid item md={4}>
          <RACTextbox
            type="text"
            name="city"
            inputlabel="City"
            value={searchCriteria.city}
            placeHolder={'City'}
            maxlength={30}
            inputLabelClassname={classes.mb1}
            OnChange={(event) => searchHandler(event, 'city')}
            OnKeydown={(e) => enterEncountered(e)}
          />
        </Grid>
        <Grid item md={4}>
          {
            <CustomDropdown
              states={states}
              selection={(value) => searchHandler(value, 'state')}
              defaultValue={searchCriteria.state}
              loading={dropDownLoading}
              inputLabel={'State'}
              isDisabled={false}
            />
          }
        </Grid>
      </Grid>
    );
  }

  return (
    <OutsideClickHandler onOutsideClick={clickedOutside}>
      <Grid
        data-testid="searchbtn"
        className={clsx({
          [classes.racGlobalSearchShow]:
            props.disPlaySearchBar == DISPLAYSEARCHBAR,
        })}
        classes={{
          root: clsx(classes.racDropdownMenu, classes.racMenuContent),
        }}
        aria-labelledby="navbarglobalsearch"
      >
        <Grid
          container
          spacing={2}
          className={`${classes.px2} ${classes.mb3} ${classes1.p3}`}
        >
          {FirstRowDependency()}
          {secondRowFirstDependency()}
          {secondRowSecondDependency()}
          <Grid item lg="auto" md={4} sm={6}>
            <RACButton
              startIcon={<SearchImageDefault />}
              disabled={disableSearchBtn}
              variant="contained"
              color="primary"
              size="small"
              className={classes.racSearchPrimary}
              onClick={invokeRedirection}
            >
              Search
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    </OutsideClickHandler>
  );
};
