/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Typography, RACCOLOR } from '@rentacenter/racstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

interface dropdown {
  label: string;
  value: string;
}

export interface DropdownProps {
  states: dropdown[];
  selection: (value: string) => void;
  defaultValue: string;
  loading: boolean;
  inputLabel: string;
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  dropdown: {
    position: 'relative',
    cursor: 'pointer',
    border: '1px solid #c4c4c4',
    borderRadius: theme.typography.pxToRem(6),
    padding: theme.typography.pxToRem(7),
    background: 'white',
    fontFamily: 'OpenSans-regular',
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.typography.pxToRem(8),
  },

  selected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: `${RACCOLOR.WASHED_BLACK}`,
  },
  selectedText: {
    fontWeight: 400,
  },
  arrow: {
    marginLeft: theme.typography.pxToRem(8),
  },
  options: {
    display: 'none',
    position: 'absolute',
    background: `${RACCOLOR.WHITE}`,
    border: '1px solid #c4c4c4',
    borderRadius: theme.typography.pxToRem(4),
    zIndex: 1000,
    marginTop: '-60px',
    left: 0,
    width: '100%',
    padding: 0,
    listStyleType: 'none',
    maxHeight: '150px',
    overflowY: 'auto',
  },
  optionsOpen: {
    display: 'block',
  },
  option: {
    padding: theme.typography.pxToRem(10),
    cursor: 'pointer',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  disabled: {
    backgroundColor: 'rgb(235, 235, 228)',
    cursor: 'default',
  },
  formLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.WASHED_BLACK}`,
    fontSize: theme.typography.pxToRem(14),
  },
  selectLabel: {
    backgroundColor: '#f0f0f0'
  },
  loading: {
    color: theme.palette.secondary.main,
    width: `${theme.typography.pxToRem(15)} !important`,
    height: `${theme.typography.pxToRem(15)} !important`,
    margin: '0.5px 0.5px 0 0'
  },
}));

export const CustomDropdown = ({ states, selection, defaultValue, loading, inputLabel, isDisabled }: DropdownProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    if(!isDisabled && !loading){
      setIsOpen(prev => !prev);
    }
  };

  const searchHandler = (value: string) => {
    selection(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <Typography
        variant="body2"
        component="label"
        className={classes.formLabel}
      >
        {inputLabel}
      </Typography>
      <div className={`${classes.dropdown} ${isDisabled || loading ? classes.disabled : ''}`} ref={dropdownRef} >
        <div className={classes.selected} onClick={handleClick}>
          <span className={classes.selectedText}>{defaultValue || 'Select'}</span>
          {loading ?
            <CircularProgress className={classes.loading} />
            : (<span className={classes.arrow} onClick={handleClick}>
              <svg fill="#000000" height="9px" width="10px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 407.437 407.437"  onClick={handleClick}>
                <polygon points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 " />
              </svg>
            </span>)
          }

        </div>
        <ul className={`${classes.options} ${isOpen ? classes.optionsOpen : ''}`}>
          {states && states.map((state) => (
            <li
              key={state.value}
              onClick={() => searchHandler(state.label)}
              className={`${classes.option} ${state.label === defaultValue ? classes.selectLabel : ''}`}
            >
              {state.label}
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};
